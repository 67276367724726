import styled from '@emotion/styled';

import { Button } from '@/components/common/Buttons';
import { visuallyHiddenStyle } from '@/components/common/utils';
import { zIndex } from '@/components/common/z-index';

import type { MutableRefObject, ReactNode } from 'react';

export interface SkipToContentButtonProps {
  children?: ReactNode;
  focusElement: MutableRefObject<HTMLElement>;
}

export const SkipToContentButton = ({
  children,
  focusElement,
}: SkipToContentButtonProps) => {
  const navigateToContent = () => {
    focusElement.current.focus();
  };

  return (
    <StyledSkipToContentButton
      variant="solid-light"
      onClick={navigateToContent}
    >
      {children ?? 'Skip to main content'}
    </StyledSkipToContentButton>
  );
};

const StyledSkipToContentButton = styled(Button)`
  position: fixed;
  top: 18px;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${zIndex.skipLinks};

  &:not(:active):not(:focus):not(:focus-visible) {
    ${visuallyHiddenStyle}
  }

  &:focus,
  &:focus-visible {
    outline-color: var(--decorative-dark-ultraviolet);
  }
  &:hover {
    outline-color: var(--surface-subtle);
  }
`;
