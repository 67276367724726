import styled from '@emotion/styled';
import { useRouter } from 'next/router';

import BaseLink from '@/components/common/BaseLink/BaseLink';
import { screen } from '@/components/common/breakpoints';
import { HeadingLevel } from '@/components/common/MarkUp';
import { WidthConstraint } from '@/components/layout/Section';
import { usePageCommon } from '@/lib/usePageCommon';
import Logo from '@/public/images/hexagonLogo.svg';

import { CountrySelector } from './CountrySelector';
import { FooterNav } from './FooterNav';
import { SocialLinks } from './SocialLinks';

import type { Locale, SiteSettings } from '@/types/shared';

export interface FooterProps extends SiteSettings {}

export const Footer = ({
  address,
  generalEmail,
  contactEmail,
  pressEmail,
  copyright,
  social,
  privacyPolicy,
  termsOfService,
}: FooterProps) => {
  const { locale } = useRouter();
  const { layout } = usePageCommon();

  return (
    <FooterElem>
      <FooterCurve
        style={{
          backgroundColor:
            layout?.lastItemBackground && layout.spacingMode !== 'margin'
              ? `var(${layout?.lastItemBackground})`
              : undefined,
        }}
      />
      <WidthConstraint>
        <FooterLayout>
          <HeadingLevel>
            <PrimaryLayout>
              <LogoWrapper aria-label="Multiverse logo" />
              <FooterNav locale={locale} />
              <LegalLinks>
                <LegalLink
                  showVisited={false}
                  href={privacyPolicy}
                  target="_blank"
                  rel="noopener"
                >
                  Privacy Policy
                </LegalLink>
                {!!contactEmail && (
                  <LegalLink showVisited={false} href={contactEmail}>
                    Contact Us
                  </LegalLink>
                )}
                {!!pressEmail && (
                  <LegalLink showVisited={false} href={pressEmail}>
                    Press Enquiries
                  </LegalLink>
                )}
                {locale === 'en-GB' && (
                  <LegalLink showVisited={false} href="/the-levy">
                    Levy
                  </LegalLink>
                )}
                <LegalLink href={termsOfService} target="_blank" rel="noopener">
                  Terms
                </LegalLink>
                {locale === 'en-GB' && (
                  <LegalLink showVisited={false} href="/our-policies">
                    Policies
                  </LegalLink>
                )}
                <LegalLink
                  href="#"
                  showVisited={false}
                  onClick={(e) => {
                    e.preventDefault();
                    if ('semaphore' in window)
                      window.semaphore?.push(['showPreferences']);
                  }}
                >
                  Privacy Settings
                </LegalLink>
              </LegalLinks>
            </PrimaryLayout>
            <SecondaryLayout>
              <CountrySelector currentLocale={locale as Locale} />
            </SecondaryLayout>
            <TertiaryLayout>
              <FooterAddress>
                {address} |{' '}
                {generalEmail && (
                  <a href={`mailto:${generalEmail}`}>{generalEmail}</a>
                )}
                <br />
                &copy; {copyright}
              </FooterAddress>
              <SocialLinks {...social} />
            </TertiaryLayout>
          </HeadingLevel>
        </FooterLayout>
      </WidthConstraint>
    </FooterElem>
  );
};

const FooterElem = styled.footer`
  position: relative;
  overflow: clip;

  padding-top: calc(var(--section-radius) + var(--spacing-xxx-large));
  padding-bottom: var(--spacing-x-large);

  z-index: 1;

  color: var(--text-strong);
  background-color: var(--background-warm-strong);

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-underline-position: under;
    }
  }
`;

const FooterCurve = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--section-radius);

  box-shadow: var(--shadow-base);
  border-bottom-left-radius: var(--section-radius);
  border-bottom-right-radius: var(--section-radius);

  background-color: var(--background-subtle);
`;

const FooterLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-large);
`;

const LogoWrapper = styled(Logo)`
  height: 64px;
  color: var(--icon-action);
  grid-area: logo;
`;

const PrimaryLayout = styled.div`
  display: grid;
  gap: var(--spacing-large) var(--spacing-medium);
  grid-template-areas:
    'logo'
    'links'
    'legal';

  ${screen.md} {
    grid-template-areas:
      'logo links'
      'legal legal';
  }

  ${screen.lg} {
    grid-template-areas: 'logo links legal';
  }
`;

const SecondaryLayout = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;

  ${screen.md} {
    justify-content: left;
  }
`;

const TertiaryLayout = styled.div`
  display: flex;

  flex-direction: column-reverse;
  align-items: center;

  ${screen.md} {
    flex-direction: row;
    align-items: right;
  }
`;

const LegalLinks = styled.div`
  grid-area: legal;

  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-small);
  justify-content: space-around;
  line-height: var(--spacing-250);

  ${screen.md} {
    flex-direction: row;
    justify-content: unset;
  }

  ${screen.lg} {
    flex-direction: column;
    grid-template-columns: 1fr;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

const LegalLink = styled(BaseLink)`
  white-space: nowrap;
  flex: 45%;
  font-weight: inherit;
  font-size: inherit;

  ${screen.md} {
    flex: unset;
  }
`;

const FooterAddress = styled.p`
  font-size: 14px;
  line-height: 1.4;

  text-align: center;
  justify-content: center;

  ${screen.md} {
    text-align: left;
  }
`;
