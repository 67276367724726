import { useMemo } from 'react';
import { useCookieValue } from '@react-hookz/web/cjs/useCookieValue';

import type { SanityAudienceType } from '@/types/sanity';
import type { Navigation_exType, SiteSettings } from '@/types/shared';

export const useNewNavigation = (
  navigations_ex: Navigation_exType[],
  siteSettings: SiteSettings,
  audienceRef?: SanityAudienceType,
) => {
  const [cookieValue, set] = useCookieValue('AudienceType', {
    path: '/',
    initializeWithValue: false,
  });

  return useMemo<Navigation_exType>(() => {
    // If AudienceSwitcher is disabled, show first of navigations, sorted by audience order
    if (
      !siteSettings.isAudienceSwitcherEnabled ||
      !siteSettings.newNavigation
    ) {
      return navigations_ex[0];
    }

    if (audienceRef) {
      const referencedNavigation_ex = navigations_ex.find(
        (nav_ex) => nav_ex.audience?._id === audienceRef._id,
      );

      if (referencedNavigation_ex) {
        const cookieName = referencedNavigation_ex.audience.name.toLowerCase();

        set(cookieName);
        return referencedNavigation_ex;
      }
    }

    const navigationByCookie = navigations_ex.find(
      (nav) => nav.audience?.name.toLowerCase() === cookieValue,
    );

    return navigationByCookie || navigations_ex[0];
  }, [
    navigations_ex,
    siteSettings?.isAudienceSwitcherEnabled,
    siteSettings?.newNavigation,
    cookieValue,
    audienceRef,
  ]);
};
