import styled from '@emotion/styled';
import { FaFacebook } from '@react-icons/all-files/fa/FaFacebook';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter';
import { FaYoutube } from '@react-icons/all-files/fa/FaYoutube';
import { SiTiktok } from '@react-icons/all-files/si/SiTiktok';
import Link from 'next/link';

import { screen } from '@/components/common/breakpoints';

import type { SanitySocialType } from '@/types/sanity';
import type { IconType } from '@react-icons/all-files/lib';

const SocialLinksContainer = styled.div`
  display: flex;
  color: var(--icon-action);
  flex-direction: row;
  justify-content: center;
  margin: 0;
  margin-bottom: var(--spacing-400);

  a {
    margin-right: var(--spacing-250);

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  svg {
    height: auto !important;
    width: 30px !important;
  }

  ${screen.md} {
    margin-left: auto;
  }
`;

interface SocialLinkProps {
  url: string;
  name: string;
  icon: IconType;
}

const SocialLink = ({ url, name, icon }: SocialLinkProps) => {
  if (!url) {
    return null;
  }
  const Icon = icon;
  return (
    <Link
      href={url}
      aria-label={name}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon aria-hidden="true" />
    </Link>
  );
};

export interface SocialLinksProps extends SanitySocialType {}

export const SocialLinks = ({
  facebook,
  instagram,
  linkedin,
  twitter,
  youtube,
  tiktok,
}: SocialLinksProps) => (
  <SocialLinksContainer>
    <SocialLink url={facebook} name="Facebook" icon={FaFacebook} />
    <SocialLink url={twitter} name="Twitter" icon={FaTwitter} />
    <SocialLink url={linkedin} name="Linkedin" icon={FaLinkedin} />
    <SocialLink url={instagram} name="Instagram" icon={FaInstagram} />
    <SocialLink url={youtube} name="YouTube" icon={FaYoutube} />
    <SocialLink url={tiktok} name="TikTok" icon={SiTiktok} />
  </SocialLinksContainer>
);
