export interface LinkProps {
  href: string;
  label: string;
  description?: string;
  isExternal?: boolean;
}

export const aboutLinks = (locale: string): LinkProps[] => {
  switch (locale) {
    case 'en-GB':
      return gbAboutLinks;
    case 'en-US':
      return usAboutLinks;
    default:
      return gbAboutLinks;
  }
};
export const usAboutLinks: LinkProps[] = [
  { href: '/about', label: 'Our Mission', description: '' },
  {
    href: '/blog',
    label: 'Blog',
    description: '',
  },
  {
    href: '/diversity',
    label: 'Diversity, Equity\nand Inclusion',
    description: '',
  },
];
export const gbAboutLinks: LinkProps[] = [
  { href: '/about', label: 'Our Mission', description: '' },
  {
    href: '/blog',
    label: 'Blog',
    description: '',
  },
  {
    href: '/diversity',
    label: 'Diversity, Equity\nand Inclusion',
    description: '',
  },
];

export const candidateLinks = (locale: string): LinkProps[] => {
  switch (locale) {
    case 'en-GB':
      return gbCandidateLinks;
    case 'en-US':
      return usCandidateLinks;
    default:
      return gbCandidateLinks;
  }
};

const gbCandidateLinks = [
  {
    href: '/young-adults',
    label: 'Start your career',
    description:
      "Earn a £18000+ salary whilst you learn at the world's top companies",
  },
  {
    href: '/professionals',
    label: 'Learn new skills',
    description: 'Develop new skills and competencies for the digital economy',
  },
];

const usCandidateLinks = [
  {
    href: '/young-adults',
    label: 'Start your career',
    description: "Earn while you learn at the world's top companies",
  },
  {
    href: '/professionals',
    label: 'Learn new skills',
    description: 'Develop new skills and competencies for the digital economy',
  },
];
