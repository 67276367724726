import styled from '@emotion/styled';
import { useCookieValue } from '@react-hookz/web/cjs/useCookieValue';
import { useRouter } from 'next/router';

import { screen } from '@/components/common/breakpoints';
import { Icon } from '@/components/common/Icon';

import type { Locale } from '@/types/shared';

interface CountrySelectorProps {
  currentLocale?: Locale;
}

const langs = [
  {
    name: 'United Kingdom',
    value: 'en-GB',
  },
  {
    name: 'United States',
    value: 'en-US',
  },
];

const Label = styled.label`
  text-align: center;
  margin-bottom: var(--spacing-small);
  margin-right: var(--spacing-small);
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  color: var(--icon-action);
`;

const Select = styled.select`
  appearance: none;
  background: none;
  border: 1px solid;
  border-radius: var(--radius-400);
  cursor: pointer;
  color: var(--text-action);
  display: inline-flex;
  font-size: 16px;
  padding: var(--spacing-150) 40px var(--spacing-150) var(--spacing-150);

  &::-ms-expand {
    display: none;
  }
`;
const SelectWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;

  ${StyledIcon} {
    pointer-events: none;
    right: var(--spacing-200);
    top: var(--spacing-650);
    position: absolute;
  }

  ${screen.sm} {
    display: inline-block;
    ${StyledIcon} {
      right: var(--spacing-200);
      bottom: 0;
      position: absolute;
      top: 50%;
    }
  }
`;

const CountrySelector = ({ currentLocale }: CountrySelectorProps) => {
  const router = useRouter();
  const [, setCookie] = useCookieValue('NEXT_LOCALE');
  const { pathname, asPath, query } = router;

  const handleChange = (e) => {
    e.preventDefault();
    const lang = e.currentTarget.value;
    setCookie(lang);
    router.push({ pathname, query }, asPath, { locale: lang });
  };

  return (
    <SelectWrapper>
      <Label htmlFor="footerLanguage">Choose country</Label>
      <Select id="footerLanguage" onChange={handleChange} value={currentLocale}>
        {langs.map(({ name, value }) => (
          <option key={value} value={value}>
            {name}
          </option>
        ))}
      </Select>
      <StyledIcon icon="ChevronUp" size="21px" aria-hidden="true" />
    </SelectWrapper>
  );
};

export { CountrySelector };
